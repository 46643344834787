<template>
  <v-card class="py-5" elevation="0">
    <v-card-title class="justify-center text-h4 pb-10 primary--text"
      ><!-- {{isUpdate?'แก้ไข':'สร้าง'}} -->สรุปผลลัพธ์งานขนส่งและอื่น
      ๆ</v-card-title
    >
    <v-card-text class="popup-background">
      <v-row justify="center" dense>
        <v-col cols="12">
          <v-data-table
            class="elevation-2"
            :headers="headers"
            :items="model.process_array"
            :search="search"
            :loading="loading"
            :loading-text="$table_loading_text"
            :page.sync="page"
            :items-per-page="itemsPerPage"
            hide-default-footer
            @page-count="pageCount = $event"
          >
            <template v-slot:[`item.margin_minus`]="{ item }">
              <div v-if="item.margin_minus" class="red--text text-center">
                {{ item.margin_minus | numeral('0,0.00') }}
              </div>
              <div v-else class="text-center">-</div>
            </template>

            <template v-slot:[`item.margin_plus`]="{ item }">
              <div v-if="item.margin_plus" class="success--text text-center">
                {{ item.margin_plus | numeral('0,0.00') }}
              </div>
              <div v-else class="text-center">-</div>
            </template>

            <template v-slot:[`item.is_refund_or_charge`]="{ item }">
              <div v-if="item.margin < 0">
                <v-btn
                  color="success"
                  class="white--text"
                  :outlined="!item.is_refund_or_charge"
                  @click="onClickRefundOrCharge(item, true)"
                  small
                  >คืนเงิน</v-btn
                >
                <span class="mx-1"></span>
                <v-btn
                  color="grey"
                  class="white--text"
                  :outlined="item.is_refund_or_charge"
                  @click="onClickRefundOrCharge(item, false)"
                  small
                  >ไม่คืนเงิน</v-btn
                >
              </div>
              <div v-else-if="item.margin > 0">
                <v-btn
                  color="red"
                  class="white--text"
                  :outlined="!item.is_refund_or_charge"
                  @click="onClickRefundOrCharge(item, true)"
                  small
                  >เก็บเพิ่ม</v-btn
                >
                <span class="mx-1"></span>
                <v-btn
                  color="grey"
                  class="white--text"
                  :outlined="item.is_refund_or_charge"
                  @click="onClickRefundOrCharge(item, false)"
                  small
                  >ไม่เก็บเพิ่ม</v-btn
                >
              </div>
            </template>

            <template v-slot:[`item.action`]="{ item }">
              <template v-if="item.margin < 0">
                <div v-if="item.is_refund_or_charge" class="text-center">
                  คืนเงิน
                </div>
                <div v-if="!item.is_refund_or_charge" class="text-center">
                  ไม่คืนเงิน
                </div>
              </template>
              <template v-else-if="item.margin > 0">
                <div v-if="item.is_refund_or_charge" class="text-center">
                  เก็บเพิ่ม
                </div>
                <div v-if="!item.is_refund_or_charge" class="text-center">
                  ไม่เก็บเพิ่ม
                </div>
              </template>
              <template v-else>
                <div class="text-center">-</div>
              </template>
            </template>

            <template v-slot:footer>
              <table-pagination
                :page.sync="page"
                :itemsPerPage.sync="itemsPerPage"
                :length="pageCount"
              />
            </template>
          </v-data-table>
        </v-col>

        <v-col cols="8" class="text-center my-5 pt-5">
          <v-card>
            <v-card-text>
              <v-row>
                <v-col cols="12" class="text-left primary--text fz-3"
                  >สรุปผลลัพธ์งานขนส่งและอื่น ๆ</v-col
                >
                <v-col cols="12">
                  <div
                    v-for="(item, index) in report_array"
                    :key="`item-${index}`"
                  >
                    <v-row>
                      <v-col cols="1">{{ index + 1 }}</v-col>
                      <v-col cols="4">{{ item.car_plate }}</v-col>
                      <v-col>{{ item.product }}</v-col>
                      <v-col cols="2">{{ item.transportation_fee }}</v-col>
                    </v-row>
                  </div>
                  <v-row class="mt-5">
                    <v-col cols="5"></v-col>
                    <v-col class="primary--text text-right">รวมทั้งสิ้น</v-col>
                    <v-col cols="2" class="primary--text fz-5">{{
                      total_price
                    }}</v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="8">
          <v-row>
            <v-col class="text-right py-5">
              <v-btn outlined class="px-5" color="grey" @click="close(1)"
                >ยกเลิก</v-btn
              >
            </v-col>
            <v-spacer />
            <v-col class="text-right py-5">
              <v-btn class="px-10" color="success" @click="save(false)">
                บันทึก ไม่สร้างรายจ่าย
              </v-btn>
            </v-col>
            <v-col class="text-right py-5">
              <v-btn
                class="px-10"
                color="primary"
                @click="save(true)"
                :loading="loading"
                :disabled="loading"
              >
                บันทึก และสร้างรายจ่าย
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: ['process_array'],
  data() {
    return {
      search: '',
      selected: [],
      loading: true,
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      model: {
        process_array: [],
        report: [],
        total_price: 0,
      },
      list: [],
      headers: [
        { text: 'ทะเบียน', value: 'car_plate' },
        { text: 'จังหวัด', value: 'province', filterable: false },
        { text: 'งานขนส่ง', width: '14%', value: 'transportation_product' },
        { text: 'งานอื่น ๆ', width: '18%', value: 'other_product' },
        { text: 'ราคางาน', value: 'price' },
        { text: 'ขนส่งเก็บ', value: 'transportation_fee', filterable: false },
        { text: 'เก็บเพิ่ม', value: 'margin_minus', filterable: false },
        { text: 'คืนเงิน', value: 'margin_plus', filterable: false },
        { text: '', value: 'is_refund_or_charge', filterable: false },
        { text: 'การดำเนินการ', value: 'action', filterable: false },
      ],
    };
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      this.loading = true;
      let payload = {
        branch_id: this.$store.state.selected_branch._id,
        process_array: this.process_array,
      };
      let body = {
        token: this.$jwt.sign(payload, this.$privateKey, { noTimestamp: true }),
      };
      await this.$axios
        .post(`${this.$baseUrl}/process/summary_transportation_job`, body)
        .then((res) => {
          this.model.process_array = this.prepareProcessArray(
            res.result.process_array
          );
          console.log(this.model.report);
        })
        .catch((err) => {
          console.log('err', err);
          this.$alertServerError({ title: err.error_message });
        });
      this.loading = false;
    },
    prepareProcessArray(process_array) {
      let list = [];
      if (
        process_array &&
        process_array.length &&
        typeof process_array === 'object'
      ) {
        for (var i = 0; i < process_array.length; i++) {
          var o = Object.assign({}, process_array[i]);
          if (o.margin <= 0) {
            o.margin_plus =
              Math.floor((o.margin * -1 + Number.EPSILON) * 100) / 100;
          } else {
            o.margin_minus =
              Math.floor((o.margin + Number.EPSILON) * 100) / 100;
          }
          o.is_refund_or_charge = false;
          list.push(o);
        }
      }
      return list;
    },
    close(tabs) {
      this.$router
        .push({ name: 'follow-transport', params: { tab: tabs } })
        .catch(() => {});
    },
    async save(is_create_report) {
      this.loading = true;
      let process_array = this.model.process_array.map((x) => {
        return {
          process_id: x._id,
          is_refund_or_charge: x.is_refund_or_charge,
        };
      });
      let payload = {
        branch_id: this.$store.state.selected_branch._id,
        is_create_report: is_create_report,
        process_array: process_array,
      };
      console.log(payload);
      let body = {
        token: this.$jwt.sign(payload, this.$privateKey, { noTimestamp: true }),
      };
      console.log(body);
      await this.$axios
        .post(`${this.$baseUrl}/process/record_transportation_job_result`, body)
        .catch((err) => {
          console.log('err', err);
          this.$alertServerError({ title: err.error_message });
        });
      this.loading = false;
      if (!is_create_report) this.close(1);
      this.close(2);
    },
    onClickRefundOrCharge(item, value) {
      item.is_refund_or_charge = value;
    },
  },
  computed: {
    report_array() {
      let array = [];
      this.model.process_array.forEach((item) => {
        array.push({
          _id: item._id,
          car_plate: item.car_plate + ' ' + item.province,
          product:
            item.transportation_product != ''
              ? item.transportation_product
              : item.other_product,
          transportation_fee: item.is_refund_or_charge
            ? item.transportation_fee
            : item.price,
        });
      });
      return array;
    },
    total_price() {
      let price = 0;
      this.model.process_array.forEach((item) => {
        if (item.is_refund_or_charge) {
          price += parseFloat(item.transportation_fee || 0);
        } else {
          price += parseFloat(item.price || 0);
        }
      });
      return Math.ceil((price + Number.EPSILON) * 100) / 100;
    },
  },
};
</script>

